import { Key, storage } from '@soluto-private/mx-context';
import { OneserviceApiClient, Plan } from './providers';
import { Partner, ProductType } from '@soluto-private/mx-types';
import {
    clearPlansAndServices,
    persistFilteredPartners,
    persistPartner,
    persistPartnerDetails,
    persistProductType,
} from './providers/storage';

import canRedirectToPartnerSelection from './util/canRedirectToPartnerSelection';
import canUseDefaultProductType from './util/canUseDefaultProductType';
import { dispatchAnalytics } from './providers/analyticsDispatcher';
import formatPlansToPartnerDetails from './util/formatPlansToPartnerDetails';
import { monitor } from '@soluto-private/mx-monitor';
import redirectToPartnerSelection from './util/redirectToPartnerSelection';
import redirectToPartnerVerification from './util/redirectToPartnerVerification';
import shouldSkipHydration from './util/shouldSkipHydration';

const dispatchPartnersAnalytics = (partners?: Partner[]) => {
    if (!partners || partners.length === 0) {
        dispatchAnalytics('OneService_NoPartnersFound');
    } else if (partners.length === 1) {
        dispatchAnalytics('OneService_OnePartnerFound', {
            partner: partners[0],
        });
    } else {
        dispatchAnalytics('OneService_MultiplePartnersFound', { partners });
    }
};

const getPlans = async () => {
    const client = new OneserviceApiClient();
    return await client.getPlans();
};

const getPlanPartners = (plans: Plan[]) =>
    Array.from(
        plans.reduce(
            (partners, { partner }) => partners.add(partner),
            new Set<Partner>()
        )
    );

const selectPartnerFromContext = (
    planPartners: Partner[]
): Partner | undefined => {
    const cachedPartner = storage.get(Key.Client);
    return (
        cachedPartner ??
        (planPartners.length === 1 ? planPartners[0] : undefined)
    );
};

const selectProductTypeFromContext = (
    plans: Plan[]
): ProductType | undefined => {
    const cachedProductType = storage.get(Key.ProductType);
    const planProductTypes = plans.map(({ productType }) => productType);
    return (
        cachedProductType ??
        (new Set(planProductTypes).size === 1 ? planProductTypes[0] : undefined)
    );
};

const handlePartnerAndProduct = (
    partner: Partner,
    productType: ProductType
) => {
    dispatchAnalytics('OneService_CachedPartnerFound', { partner });

    const isValidPartner = Object.values(Partner).includes(partner);
    if (!isValidPartner) {
        const error = new Error(`Partner '${partner}' is not supported!`);
        monitor.warning(error.message, error, { partner });
    }

    persistPartner(partner);
    persistProductType(productType);
};

const handlePartnerPlans = async (
    partner: Partner,
    plans: Plan[],
    redirectUrl?: string
) => {
    console.log('handle partner plans');
    clearPlansAndServices();

    const hasVerifiedPlans = plans.some((p) => p.verified);
    if (!hasVerifiedPlans) {
        console.log('no verified plans, redirecting to partner verification');
        await redirectToPartnerVerification(partner, redirectUrl);
    } else {
        console.log('valid plans found, updating context');
        const details = formatPlansToPartnerDetails(plans);
        persistPartnerDetails(details);
        dispatchAnalytics('OneService_PartnerSelected_ContextUpdated', {
            partner,
            plans: details?.plans.map(({ plan }) => plan.plan),
            services: details?.services.join(', '),
        });
    }
};

const hydratePlansAndServices = async (): Promise<void> => {
    if (shouldSkipHydration(window.location.pathname)) return;

    console.log('hydrating plans and services');

    const plans = await getPlans();
    const planPartners = getPlanPartners(plans);
    persistFilteredPartners(planPartners);

    console.log('dispatching partner analytics');
    dispatchPartnersAnalytics(planPartners);
    console.log('finished dispatching partner analytics');

    const partner = selectPartnerFromContext(planPartners);
    const partnerPlans = plans.filter((p) => p.partner === partner);
    let productType = selectProductTypeFromContext(partnerPlans);
    if (partner && !productType && (await canUseDefaultProductType(partner))) {
        productType = ProductType.Default;
    }

    if (partner && productType) {
        handlePartnerAndProduct(partner, productType);
        await handlePartnerPlans(partner, partnerPlans);
    } else if (canRedirectToPartnerSelection()) {
        const shouldFilterPartners = planPartners.length > 1;
        redirectToPartnerSelection(shouldFilterPartners);
    }
};

/**
 * Used by partners MX to select and hydrate a partner. The provided redirectUrl will be used
 * if the user is sent through the verification flow; post-verification the user will be redirected
 * back to the provided URL.
 * @param partner Partner
 * @param productType ProductType
 * @param redirectUrl string
 */
export const selectPartner = async (
    partner: Partner,
    productType: ProductType,
    redirectUrl?: string
) => {
    persistPartner(partner);
    persistProductType(productType);

    const plans = await getPlans();
    const planPartners = getPlanPartners(plans);
    persistFilteredPartners(planPartners);
    dispatchPartnersAnalytics(planPartners);

    const partnerPlans = plans.filter((p) => p.partner === partner);
    handlePartnerAndProduct(partner, productType);
    await handlePartnerPlans(partner, partnerPlans, redirectUrl);
};

export default hydratePlansAndServices;
