import {
    AuthenticationState,
    EventName,
    addEventListener,
    getAuthenticationState,
} from '@soluto-private/mx-app-authentication';
import {
    Key,
    listen,
    queryHydrator,
    storage,
    storageEvents,
} from '@soluto-private/mx-context';

import { clearPlansAndServices } from './providers/storage';
import hydratePlansAndServices from './hydratePlansAndServices';
import redirectToPartnerSelection from './util/redirectToPartnerSelection';

const isLoggedIn = () =>
    getAuthenticationState() === AuthenticationState.LoggedIn;

const listenerToPartnerEvents = async () => {
    console.log('entered listener to partner events');
    if (isLoggedIn()) {
        console.log('logged in, hydrating plans and services');
        await hydratePlansAndServices();
    } else {
        console.log('adding hydrate plans and services event listener');
        addEventListener(
            EventName.LoggedIn,
            () => void hydratePlansAndServices()
        );
        listen(storageEvents.keyValidationError(Key.Client), () =>
            redirectToPartnerSelection()
        );
    }
};

const onLoad = async (): Promise<void> => {
    const hydrated = queryHydrator.hydrate(storage);

    if (hydrated) {
        clearPlansAndServices();
    }

    await listenerToPartnerEvents();
};

export default onLoad;
